import { deepMerge } from '@tyto/utils'

import { Task } from '../../types'

export const deepMergeTask = <T extends Task = Task>(
	target: T,
	source: Partial<T>
): T => {
	const result = deepMerge(target, source)

	// Redeclare these fields because deepMerge concats arrays
	const redeclareFields: (keyof Task)[] = [
		'descrJson',
		'childSortOrder',
		'followers',
		'parents',
		'projectQuestions',
		'repeatHistory',
		'workflowData',
		'tags',
	]
	for (const field of redeclareFields) {
		if (field in source && source[field]) {
			result[field] = source[field]
		}
	}

	return result
}
