import * as Sentry from '@sentry/react'
import { fromJS } from 'immutable'
import { applyMiddleware, createStore } from 'redux'
import { combineReducers } from 'redux-immutable'
import createSagaMiddleware from 'redux-saga'

import { CHANGE_ROUTE } from './actions'
import { createSocketMiddleware } from './createSocketMiddleware'
import { history } from './history'
import { initSagas } from './init-sagas'
import * as reducers from './reducers'
import socketEventToActionMap from './socketEventToActionMap'

const { NX_SOCKET_URL } = process.env

export const configureStore = (preloadedState = {}) => {
	const sagaMiddleware = createSagaMiddleware()
	const socketMiddleware = createSocketMiddleware(
		NX_SOCKET_URL,
		socketEventToActionMap
	)
	const changeRouteMiddleware = () => (next) => (action) => {
		if (action.type === CHANGE_ROUTE) {
			history.push(action.payload)
		}
		return next(action)
	}

	const middleware = [sagaMiddleware, socketMiddleware, changeRouteMiddleware]

	/*if (process.env.NODE_ENV === 'storybook') {
		const reduxListener = require( 'storybook-addon-redux-listener' ).default()
		middleware.push(reduxListener)
	}*/

	if (process.env.NODE_ENV !== 'production') {
		//middleware.unshift( require( 'redux-immutable-state-invariant' ).default() )
	}

	const store = createStore(
		combineReducers(reducers),
		fromJS(preloadedState),
		applyMiddleware(...middleware)
	)

	initSagas(sagaMiddleware)

	// if (module.hot) {
	// 	// Enable Webpack hot module replacement for reducers
	// 	module.hot.accept('./reducers', () => {
	// 		const nextReducer = require('./reducers')
	// 		store.replaceReducer(nextReducer)
	// 	})
	// }

	return store
}
