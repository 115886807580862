import { AxiosInstance } from 'axios'
import axiosRetry from 'axios-retry'
import { formatISO } from 'date-fns'
import { omit } from 'remeda'
import { Ok, Result } from 'ts-results' // changing here would need changing in api/files-controller too

import { apiResponseErrorHandler } from '../../api-adapter/error-handler'
import { convertApiFileToAttachment } from '../../helpers/files'
import { WrappedTaskActivityV2 } from '../../task-activity/task-activity-schemas'
import useStore from '../useStore'
import {
	ApiAdapter,
	ApiChatMessage,
	ApiFile,
	ApiListResult,
	LoginResponse,
	UserActivityListParams,
} from './baseApiAdapter'

const paginationPatch = (data: any = []) => {
	if (data.items) {
		return data
	} else {
		return {
			count: data.length,
			items: data,
			hasMore: false,
			page: 1,
			pageSize: 0,
		}
	}
}

export const createAxiosApiAdapter = (
	apiInstance: AxiosInstance
): ApiAdapter => {
	axiosRetry(apiInstance, {
		retries: 10,
		retryDelay: axiosRetry.exponentialDelay,
		retryCondition: (error) => {
			if (
				error.response?.status === 503 ||
				error.code === 'ERR_NETWORK'
			) {
				return true
			}
			return false
		},
	})

	apiInstance.interceptors.response.use(null, apiResponseErrorHandler)

	return {
		apiInstance,
		debug: {
			simulateErrorResponse: async () =>
				apiInstance.get('/debug/simulate-error-response'),
		},
		auth: {
			login: async (email, password, config) =>
				apiInstance
					.post<LoginResponse>('/login', { email, password }, config)
					.then((response) => response.data),
		},
		billing: {
			cancelSubscription: async (config) =>
				apiInstance
					.delete('/billing/plan', config)
					.then((response) => response.data),
			getDetails: async (config) =>
				apiInstance
					.get('/billing/details', config)
					.then((response) => response.data),
			getInvoices: async (params = {}, config) =>
				apiInstance
					.get('/billing/invoices', { params, ...config })
					.then((response) => response.data)
					.then(paginationPatch),
			getPlan: async (config) =>
				apiInstance
					.get('/billing/plan', config)
					.then((response) => response.data),
			getSavedPaymentMethods: async (config) =>
				apiInstance
					.get('/billing/paymentMethods', config)
					.then((response) => response.data),
			removePaymentMethod: async (paymentMethodId, config) =>
				apiInstance
					.delete(
						`/billing/paymentMethods/${paymentMethodId}`,
						config
					)
					.then((response) => response.data),
			setDefaultPaymentMethod: async (paymentMethodId, config) =>
				apiInstance
					.post(
						`/billing/paymentMethods/${paymentMethodId}/setDefault`,
						config
					)
					.then((response) => response.data),
			updateDetails: async (changes, config) =>
				apiInstance
					.put('/billing/details', changes, config)
					.then((response) => response.data),
			updatePlan: async (payload, config) =>
				apiInstance
					.put('/billing/plan', payload, config)
					.then((response) => response.data),
		},
		chat: {
			addMessageReaction: async (messageId, reaction, config) =>
				apiInstance
					.post(
						`/chat/messages/${messageId}/reactions`,
						{ reaction },
						config
					)
					.then((response) => response.data),
			removeMessageReaction: async (messageId, reaction, config) =>
				apiInstance
					.delete(`/chat/messages/${messageId}/reactions`, {
						...config,
						data: { reaction },
					})
					.then((response) => response.data),
			archiveRoom: async (roomId, params = { archive: true }, config) =>
				apiInstance
					.put(`/chat/rooms/${roomId}/archive`, params, config)
					.then((response) => response.data),
			addRoom: async (chatRoom, config) =>
				apiInstance
					.post(
						`/chat/rooms`,
						{ name: chatRoom.name, userIds: chatRoom.users },
						config
					)
					.then((response) => response.data),
			updateRoom: async (roomId, changes, config) =>
				apiInstance
					.put(
						`/chat/rooms/${roomId}`,
						{ name: changes.name, userIds: changes.users },
						config
					)
					.then((response) => response.data),
			getAllMessages: async (params = {}, config) =>
				apiInstance
					.get('/chat/messages', { params, ...config })
					.then((response) => response.data)
					// Temporary mapping till we can fix the pagination on the backend
					.then(paginationPatch)
					.then((apiListResult: ApiListResult<ApiChatMessage>) => ({
						...apiListResult,
						items: apiListResult.items.map((item) => ({
							...item,
							files: (item.files || []).map((file) =>
								convertApiFileToAttachment(
									apiInstance.defaults.baseURL || '',
									useStore.getState().player.authToken || '',
									file
								)
							),
						})),
					})),
			getRoomMessages: async (
				roomId,
				params = { page: 1, pageSize: 30 },
				config
			) =>
				apiInstance
					.get(`/chat/rooms/${roomId}/messages`, {
						params: { ...params, isNew: true },
						...config,
					})
					.then((response) => response.data)
					.then((apiListResult: ApiListResult<ApiChatMessage>) => ({
						...apiListResult,
						items: apiListResult.items.map((item) => ({
							...item,
							files: item.files.map((file) =>
								convertApiFileToAttachment(
									apiInstance.defaults.baseURL || '',
									useStore.getState().player.authToken || '',
									file
								)
							),
						})),
					})),
			getRooms: async (config) =>
				apiInstance
					.get('/chat/rooms', config)
					.then((response) => response.data)
					// Temporary mapping till we can fix the pagination on the backend
					.then(paginationPatch),
			sendMessage: async (roomId, message, config) =>
				apiInstance
					.post(
						`/chat/rooms/${roomId}/messages`,
						omit(message, ['files']),
						config
					)
					.then((response) => response.data),
			sendMessageFiles: async (messageId, files, config) => {
				if (files.length > 0) {
					const formData = new FormData()
					files.forEach((file) => {
						formData.append('fileIds', file.id)
						formData.append('files', file as unknown as Blob)
					})
					return apiInstance
						.post(`/chat/messages/${messageId}/files`, formData, {
							headers: {
								Accept: 'application/json',
								'Content-Type': 'multipart/form-data',
							},
							...config,
						})
						.then((response) => response.data)
						.then(({ data }) => {
							const results = data.files as Result<
								ApiFile,
								Error
							>[]

							const isOk = (
								result: Result<ApiFile, Error>
							): result is Ok<ApiFile, Error> => result.ok

							return results
								.filter(isOk)
								.map((result) => result.val)
								.map((file) => {
									if (!file) {
										console.debug(
											'axiosApiAdapter.sendMessageFiles invalid file',
											{
												file,
												results,
												sendMessageFilesArgs: {
													messageId,
													files,
													config,
												},
											}
										)
									}
									return convertApiFileToAttachment(
										apiInstance.defaults.baseURL || '',
										useStore.getState().player.authToken ||
											'',
										file
									)
								})
						})
				}

				return []
			},
			typing: async (roomId, config) =>
				apiInstance
					.post(`/chat/rooms/${roomId}/typing`, {}, config)
					.then((response) => response.data),
			updateLastReadDate: (roomId, lastReadDate, config) =>
				apiInstance
					.put(
						`/chat/rooms/${roomId}/lastReadDate`,
						{ lastReadDate },
						config
					)
					.then((response) => response.data),
		},
		player: {
			addUserEmail: (email, config) =>
				apiInstance
					.post('/user-emails', { email }, config)
					.then((response) => response.data),
			calendarSyncGoogle: async (config) =>
				apiInstance
					.get('/calendarSync/google', config)
					.then((response) => response.data),
			defaultUserEmail: (control, config) =>
				apiInstance
					.post(`/user-emails/${control}/make-default`, null, config)
					.then((response) => response.data),

			deleteUserEmail: (control, config) =>
				apiInstance
					.delete(`/user-emails/${control}`, config)
					.then((response) => response.data),

			getEmails: async (config) =>
				apiInstance
					.get('/user-emails', config)
					.then((response) => response.data),
			getMentions: async (config) =>
				apiInstance
					.get('/user-mentions', config)
					.then((response) => response.data),
			getOrganisation: async (config) =>
				apiInstance
					.get(`/users/me/organisation`, config)
					.then((response) => response.data),
			getRecentlyViewedProjects: async (params = { limit: 5 }, config) =>
				apiInstance.get('/users/me/recents/recents.viewedProjects', {
					params,
					...config,
				}),
			resendEmailVerification: (control, config) =>
				apiInstance
					.post(`/user-emails/${control}/resend`, null, config)
					.then((response) => response.data),
			resolveMention: (mentionId, config) =>
				apiInstance
					.post(`/user-mentions/${mentionId}/resolve`, null, config)
					.then((response) => response.data),
			resolveAllMentions: () =>
				apiInstance
					.post(`/user-mentions/resolve-all`)
					.then((response) => Boolean(response.data)),
			update: async (changes, config) =>
				apiInstance
					.put('/users/me', changes, config)
					.then((response) => response.data),
		},
		reports: {
			getProjectTimesheet: async (projectId, params = {}, config) =>
				apiInstance
					.get(`/reports/project-timesheet/${projectId}`, {
						params,
						...config,
					})
					.then((response) => response.data),
		},
		settings: {
			mergeUser: async (mergeEmail, config) =>
				apiInstance
					.post('/users/request-merge', { email: mergeEmail }, config)
					.then((response) => response.data),
		},
		tasks: {
			add: async (task, position, config) =>
				apiInstance
					.post(
						'/tasks',
						position ? { ...task, position } : task,
						config
					)
					.then((response) => response?.data),
			addActivity: async (activity: WrappedTaskActivityV2) => {
				return apiInstance
					.post(`/tasks/${activity.taskId}/comments`, activity)
					.then((response) => response.data)
			},
			addActivityReaction: async (taskId, activityId, reactionType) =>
				apiInstance
					.put(
						`/tasks/${taskId}/activity/${activityId}/reactions/${encodeURIComponent(
							reactionType
						)}`
					)
					.then((response) => response.data),
			addFollower: async (taskId, follower, config) =>
				apiInstance
					.post(`/tasks/${taskId}/followers`, { follower }, config)
					.then((response) => response.data),
			addMultiple: async (tasks, config) =>
				apiInstance
					.patch('/tasks', tasks, config)
					.then((response) => response.data),
			getActivity: async (taskId, params = { type: 'all' }, config) => {
				// Omit the type if it's equal to all because the api doesn't
				// accept all as a value
				const refinedParams =
					params.type === 'all' ? omit(params, ['type']) : params

				return taskId
					? apiInstance
							.get(`/tasks/${taskId}/activity`, {
								params: {
									page: 1,
									pageSize: 10,
									...refinedParams,
								},
								...config,
							})
							.then((response) => response.data)
							// Temporary mapping till we can fix the pagination on the backend
							.then(paginationPatch)
					: Promise.reject(
							new Error(
								`ApiAdapter.tasks.getActivity: a taskId is required, '${taskId}' given`
							)
						)
			},
			getActivityV2: async (taskId, params = {}, config) => {
				const conf = { params, ...config }

				// NOTE: We can't use an environment variable here because for the mobile app we need to embed the environment
				// variables at build time so they won't be accessible in the usual way.
				if (apiInstance.defaults.baseURL) {
					conf.baseURL = apiInstance.defaults.baseURL.replace(
						'/v1',
						'/v2'
					)
				}

				return taskId
					? apiInstance
							.get(`/tasks/${taskId}/activity`, conf)
							.then((response) => response.data)
							// Temporary mapping till we can fix the pagination on the backend
							.then(paginationPatch)
					: Promise.reject(
							new Error(
								`ApiAdapter.tasks.getActivityV2: a taskId is required, '${taskId}' given`
							)
						)
			},
			removeActivityReaction: (taskId, activityId, reactionType) =>
				apiInstance
					.delete(
						`/tasks/${taskId}/activity/${activityId}/reactions/${encodeURIComponent(
							reactionType
						)}`
					)
					.then((response) => response.data),
			getDetail: async (taskId, config) =>
				taskId
					? apiInstance
							.get(`/tasks/${taskId}`, config)
							.then((response) => response.data)
					: Promise.reject(
							new Error(
								`ApiAdapter.tasks.getDetail: a taskId is required, '${taskId}' given`
							)
						),
			getFiles: async (taskId, config) =>
				taskId
					? apiInstance
							.get(`/tasks/${taskId}/files`, config)
							.then((response) => response.data)
							.then((files) =>
								files.map((file: ApiFile) =>
									convertApiFileToAttachment(
										apiInstance.defaults.baseURL || '',
										useStore.getState().player.authToken ||
											'',
										file
									)
								)
							)
					: Promise.reject(
							new Error(
								`ApiAdapter.tasks.getFiles: a taskId is required, '${taskId}' given`
							)
						),
			getList: async (params, config) =>
				apiInstance
					.get('/tasks', { params, ...config })
					.then((response) => response.data),
			getListOfAllUsersTasks: async (userId, config) =>
				// Gets all of user's tasks from admin perspective. Used only
				// for deleting team members.
				apiInstance
					.get(`/tasks/all?forUserId=${userId}`, config)
					.then((response) => response.data),
			getReminders: async (taskId, config) =>
				apiInstance
					.get(`/tasks/${taskId}/reminder`, config)
					.then((response) => response.data),
			getTimesList: async (taskId, params, config) =>
				apiInstance
					.get(`/tasks/${taskId}/times`, { params, ...config })
					.then((response) => response.data),
			getTimesGroupedList: async (taskId, params, config) =>
				apiInstance
					.get(`/tasks/${taskId}/timesGrouped`, { params, ...config })
					.then((response) => response.data),
			markAsDone: async (taskId, commentData, config) => {
				if (!taskId) {
					return Promise.reject(
						new Error(`No task id provided: '${taskId}'`)
					)
				}

				return apiInstance
					.post(
						`/tasks/${taskId}/setDone`,
						commentData && commentData.html
							? {
									comment: commentData.html,
									draftModel: commentData.draftModel,
								}
							: {},
						config
					)
					.then((response) => response.data)
			},
			move: async (taskId, params) =>
				apiInstance
					.post(`/tasks/${taskId}/move`, params)
					.then((response) => response.data),
			moveBulk: async (taskIds, params) =>
				apiInstance
					.patch(`/tasks/${taskIds.join(',')}/move`, params)
					.then((response) => response.data),
			remove: async (taskId, config) =>
				taskId
					? apiInstance
							.put(
								`/tasks/${taskId}`,
								{
									statusCode: 'deleted',
									deletedDate: formatISO(Date.now()),
								},
								config
							)
							.then((response) => response.data)
					: Promise.reject(
							new Error(`No task id provided: '${taskId}'`)
						),
			removeFollower: async (taskId, followerId, config) =>
				taskId
					? followerId
						? apiInstance
								.delete(
									`/tasks/${taskId}/followers/${followerId}`,
									config
								)
								.then((response) => response.data)
						: Promise.reject(
								new Error(
									`No follower id provided: '${followerId}'`
								)
							)
					: Promise.reject(
							new Error(`No task id provided: '${taskId}'`)
						),
			removeReminder: (taskId, config) =>
				apiInstance
					.delete(`/tasks/${taskId}/reminder`, config)
					.then((response) => response.data),
			setReminder: (taskId, date, config) =>
				apiInstance
					.post(`/tasks/${taskId}/reminder`, { date }, config)
					.then((response) => response.data),
			update: async (taskId, changes, config) =>
				taskId
					? apiInstance
							.put(`/tasks/${taskId}`, changes, config)
							.then((response) => response.data)
					: Promise.reject(
							new Error(`No task id provided: '${taskId}'`)
						),
			updateBulk: async (taskIds, changes, config) =>
				taskIds.length > 0
					? apiInstance
							.patch(
								`/tasks/${taskIds.join(',')}`,
								changes,
								config
							)
							.then((response) => response.data)
					: Promise.reject(
							new Error(`No task ids provided: '${taskIds}'`)
						),
			updateTimer: async (taskId, currentTimer) =>
				apiInstance
					.post(`/tasks/${taskId}/timers`, currentTimer)
					.then((response) => response.data),

			uploadMultipleFiles: async (taskId, attachments, config) => {
				console.log('uploadMultipleFiles', attachments.length)
				if (attachments.length === 0) {
					return Promise.resolve([])
				}

				const formData = new FormData()
				attachments.forEach((attachment) => {
					formData.append('fileIds', attachment.id)
					formData.append('files', attachment.file)
				})

				return apiInstance
					.post(`/tasks/${taskId}/files`, formData, {
						headers: { 'Content-Type': 'multipart/form-data' },
						...config,
					})
					.then((response) => response.data)
					.then((response) =>
						response.data.files
							.filter((result) => result.ok)
							.map((result) => result.val)
					)
			},

			removeFile: async (taskId, fileId, config) =>
				apiInstance
					.delete(`/tasks/${taskId}/files/${fileId}`, config)
					.then((response) => response.data),

			updateFile: async (taskId, fileId, changes, config) =>
				apiInstance
					.put(
						`/tasks/${taskId}/files/${fileId}`,
						{ filename: changes.name },
						config
					)
					.then((response) => response.data),
			fetchTaskLog: async (logId, config) =>
				apiInstance
					.get(`/taskLogs/${logId}`, config)
					.then((response) => response.data),
		},
		users: {
			deleteAccount: async (config) =>
				apiInstance
					.post(`/users/me/delete`, config)
					.then((response) => response.data),
			disableUser: async (userId, config) =>
				userId
					? apiInstance
							.post(`/users/${userId}/disable`, config)
							.then((response) => response.data)
					: Promise.reject(
							new Error(
								`ApiAdapter.users.disableUser: a userId is required, '${userId}' given`
							)
						),
			getActivity: async (
				userId,
				params = {} as UserActivityListParams,
				config
			) =>
				userId
					? apiInstance
							.get(`/users/${userId}/activity`, {
								params,
								...config,
							})
							.then((response) => response.data)
					: Promise.reject(
							new Error(
								`ApiAdapter.users.getActivity: a userId is required, '${userId}' given`
							)
						),
			getDetail: async (userId, config) =>
				userId
					? apiInstance
							.get(`/users/${userId}`, config)
							.then((response) => response.data)
					: Promise.reject(
							new Error(
								`ApiAdapter.users.getDetail: a userId is required, '${userId}' given`
							)
						),
			getList: async (params = {}, config) =>
				apiInstance
					.get('/users', { params, ...config })
					.then((response) => response.data),
			getOptions: async (userId, optionIds = [], config) =>
				apiInstance
					.get(
						`/users/${userId}/options/${optionIds.join(';')}`,
						config
					)
					.then((response) => response.data),
			getSections: async (userId, config) =>
				apiInstance
					.get(`/users/${userId}/sections`, config)
					.then((response) => response.data)
					.then(({ displayOrder, items }) => ({
						byId: items,
						displayOrder,
					})),
			getUsersFocus: async () =>
				apiInstance
					.get(`/users/focus`)
					.then((response) => response.data),
			getVideoCallStatus: async () =>
				apiInstance
					.get(`/users/videoCallStatus`)
					.then((response) => response.data),
			setUsersFocus: async (userFocus) =>
				apiInstance
					.post(`/users/focus`, userFocus)
					.then((response) => response.data),
			updateOptions: async (userId, data, config) =>
				apiInstance
					.put(`/users/${userId}/options`, data, config)
					.then((response) => response.data),
			updateSection: async (userId, sectionId, changes, config) =>
				apiInstance
					.put(
						`/users/${userId}/sections/${sectionId}`,
						changes,
						config
					)
					.then((response) => response.data),
			inviteUser: async (fields, config) =>
				apiInstance
					.post(`/users/invite`, fields, config)
					.then((response) => response.data),
		},
		workflows: {
			add: async (workflow, config) =>
				apiInstance
					.post('/workflows', workflow, config)
					.then((response) => response.data),
			getList: async (params = {}, config) =>
				apiInstance
					.get('/workflows', { params, ...config })
					.then((response) => response.data)
					.then((workflows) => ({
						count: workflows?.length || 0,
						hasMore: false,
						items: workflows,
						page: 1,
						pageSize: 100,
					})),
			remove: async (workflowId, config) =>
				apiInstance
					.delete(`/workflows/${workflowId}`, config)
					.then((response) => response.data),
			update: async (workflowId, changes, config) =>
				apiInstance
					.put(`/workflows/${workflowId}`, changes, config)
					.then((response) => response.data),
		},
	}
}
